<template src="./pendency.html" />

<script>
import { computed } from "vue"
import mixins from "@/mixins/mixins.js"
import customIcon from "vue-icon/lib/vue-feather.esm"
import { useStore } from "@/store/store.js"

export default {
  name: "Pendency",

  components: {
    customIcon,
  },

  mixins: [mixins],

  data: () => ({
    windowWidth: 0,
    showWebTable: true,
    showWebButtons: true,
    specialities: [],
    searchingPendency: "",
    currentx: 1,
    numPages: 1,
    searchedWord: "",
    pagination: false,
    toRerenderWebTable: 0,
    toRerenderMobileTable: 0,
    selectedSchedule: null,
    selectedScheduleToCancel: null,
    showModalRecharge: false,
  }),

  computed: {
    isProject() {
      return this.$session.get("isProject") ? this.$session.get("isProject") : false;
    },
    clinicAddress () {
      if (this.selectedSchedule) {        
        const { match } = this.selectedSchedule
        let clinicAddress = ""
        if(match && match.clinic) {
          const { address } = match.clinic
          clinicAddress = address.street
          if (address.number) {
            clinicAddress += `, ${address.number}`
          }
          
          if (address.city && address.state) {
            clinicAddress += `${address.city}/${address.state}`
          } else if (address.city) {
            clinicAddress += ` - ${address.city}`
          }
          if (address.zipcode) {
            clinicAddress += ` - ${address.zipcode}`
          }
        }
        return clinicAddress
      }
      return ""
    },
    matches() {
      if (this.selectedSchedule) {
        const { match } = this.selectedSchedule
        return Array.from({ length: 3 })
          .map((_, index) => ({
            dateHour: index ? match[`dateHour${index}`] : match.dateHour,
            attendance: match[`kindAttendance${index}`],
          }))
          .filter((val) => val.dateHour && val.attendance )
      }
      return []
    },
  },

  setup() {
    const store = useStore()

    return {
      isDesktop: computed(() => store.state.isDesktop),
      getUserWallet: () => store.dispatch("getUserWallet"),
      setScheduleConfirmation: (data) => store.dispatch("setScheduleConfirmation", data),
    }
  },

  watch: {
    currentx: function () {
      const vm = this;
      vm.handleChangePage();
    },
  },

  mounted: function () {
    this.getPendency()
  },
  
  methods: {
    handleSearch(searching) {
      this.searchedWord = searching;
      this.currentx = 1;
      setTimeout(() => {
        this.getPendency()
      }, 1000);
    },

    updateList(){
      this.searchedWord = "";
      this.currentx = 1;
      
      this.toRerenderWebTable += this.toRerenderWebTable === 0 ? 1 : -1;
      this.toRerenderMobileTable += this.toRerenderMobileTable === 0 ? 1 : -1;
        
      this.getPendency();
    },

    handleChangePage () {
      this.getPendency()
      window.scrollTo({ top: 0, behavior: "smooth" })
    },

    getPendency() {
      const vm = this;
      let page = 0;
      if (vm.currentx > 0) {
        if (vm.currentx == 1) {
          page = 0;
        } else {
          page = vm.currentx - 1;
        }
      }
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "schedules?page=" +
            page +
            "&search=" + vm.searchedWord + "&size=10&sort=createDate,desc",
          {
            headers: {
              Authorization: vm.$session.get("bearer"),
            },
          }
        )
        .then(function (response) {
          vm.specialities = response.data.content;
          vm.numPages = response.data.totalPages;
          vm.pagination = vm.numPages > 1;
          for (const s in vm.specialities) {
            vm.specialities[s].requestDate = vm.specialities[s].dates[0].date;
            vm.specialities[s].specialtyName =
              vm.specialities[s].specialty.name !== null
                ? vm.specialities[s].specialty.name
                : "Não definido";
            vm.specialities[s].statusPtBr =
              vm.specialities[s].status == "WAITING_MATCH"
                ? "Solicitado"
                : vm.specialities[s].status == "WAITING_APPROVE"
                  ? "Confirme sua consulta"
                  : "Não definido";
            vm.specialities[s].dayPeriod =
              vm.specialities[s].dates[0].initial == "12:00"
                ? "Período da tarde"
                : vm.specialities[s].dates[0].end == "12:00"
                  ? "Período da manhã"
                  : "Qualquer horário";
          }
        })
        .catch(function (error) {
          if(error.name != "NavigationDuplicated") {
            vm.$vs.notify({
              title: "Atenção",
              text:
                "Não foi possível listar as solicitações de consultas e exames pendentes.  Entre em contato com a equipe de atendimento para informar o ocorrido através do e-mail: contato@filoo.com.br ou pelo whatsapp 11 99650-7610.",
              color: "warning",
              position: "top-right",
            });
          }
          else console.log({error:error});
        });
    },

    showModalCancel(clinic) {
      this.selectedScheduleToCancel = clinic

      setTimeout(() => this.$bvModal.show("modal-confirm-cancellation"), 100)
    },

    showModalConfirmation(clinic) {
      this.selectedSchedule = clinic
      
      setTimeout(() => this.$bvModal.show("modal-pendency-confirm-attendance"), 100)
    },    

    async handleClickConfirmDate(id, attendanceIndex, attendanceType) {
      const { avaliableBalance } = await this.getUserWallet()
      
      if (this.selectedSchedule.price <= avaliableBalance) {
        this.loader(true)
        this.$bvModal.hide("modal-pendency-confirm-attendance")

        const { success, status } = await this.setScheduleConfirmation({
          id,
          attendanceIndex,
          attendanceType,
        })

        setTimeout(async () => {
          this.loader()
  
          if (success) {
            await this.getPendency()
  
            await this.$vs.notify({
              title: "Operação realizada",
              text: "A consulta foi agendada com sucesso.",
              color: "success",
              icon: "done_all",
              time: 4000,
              position: "top-right",
            })
          } else {
            if (status === 418) {
              this.showNotifyWarning(
                "Não foi possível aceitar a consulta pois o horário solicitado é anterior ao horário atual.",
                4000
              );
            } else {
              this.showNotifyError("Não foi possível aceitar a consulta", 7000);
            }
          }
        }, 250)
      } else {
        this.showModalRecharge = true
        setTimeout(() => this.$bvModal.show("modal-recharge"), 100)
      }
    },

    handleClickConfirmCancelation(schedule) {
      this.$bvModal.hide("modal-confirm-cancellation")

      this.axios.put(
        `${this.$store.state.filooServer}schedules/${schedule.id}/cancel`,
        "",
        { headers: { Authorization: this.$session.get("bearer") }}
      )
        .then((response) => {
          if (response.status === 200) {
            this.$bvModal.hide("modal-pendency-confirm-attendance");
            this.getPendency();
            this.showNotifySuccess(
              "Operação realizada",
              "A consulta foi cancelada com sucesso.",
              2000
            );
          } else {
            this.$bvModal.hide("modal-pendency-confirm-attendance");
            this.getPendency();
            this.showNotifyError("Não foi possível cancelar a consulta", 6000);
          }
        })
        .catch(() => {
          this.$bvModal.hide("modal-pendency-confirm-attendance");
          this.getPendency();
          this.showNotifyError(
            "Não foi possível realizar a requisição para cancelamento da consulta",
            6500
          );
        });
    },
    
    clearModalVariables() {
      setTimeout(() => {
        this.selectedSchedule = null
        this.selectedScheduleToCancel = null
      }, 250)
    },
  },
};
</script>

<style lang="scss" src="./pendency.scss" />